import React from 'react';

const Experience = () => {
	return (
		<div id="experience" className="experience">
			<div className="d-flex justify-content-center my-5">
				<h1>experience</h1>
			</div>
			<div className="container experience-wrapper">
				<div className="timeline-block timeline-block-right">
					<div className="marker"></div>
					<div className="timeline-content">
						<h3>2011-2013</h3>
						<p>
							We oversaw the lifecycle of product development on both the web
							and mobile end of the HypedMusic platform — from genesis to market
							release. we played a key role in coordinating engineering efforts,
							defining product features, and designing the front-end of the
							products. This platform ranked 45th on Top New Free Music Apps on
							Play Store; 28th on Top Free Music Apps on U.S. iTunes store, 1st
							on Top Free Music Apps on France and other international iTunes
							stores. Served over 15,000 unique visitors a day and 10+ million
							page hits a month before team moved onto new projects.
						</p>
					</div>
				</div>
				{/* - */}
				<div className="timeline-block timeline-block-left">
					<div className="marker"></div>
					<div className="timeline-content">
						<h3>2018-Present</h3>
						<p>
							We help organizations supervise the lifecycles of their software
							development. On the management side we have experience with
							facilitating scrum meetings, defining scope, creating product
							backlogs, budgeting, prioritizing tasks within sprints, conducting
							sprint reviews, and communicating with c-suite members. We have
							worked with both Asana and Jira for project management software.
							We have also managed off-shore dev teams through the dev and QA
							processes. On the technical side we have gone through tedious
							refactoring, and entire re-writes of applications, large and small
							scale. We have shipped code using multiple javascript frameworks
							including ReactJS and angular. With regards to databases We have
							worked with NoSQL and MySQL. Additionally, we have collaborated
							with server-ops teams to configure AWS for deployment. Finally, we
							have played key roles in conducting QA before product releases.
						</p>
					</div>
				</div>
				{/* - */}
			</div>
		</div>
	);
};

export default Experience;
