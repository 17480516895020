import React from 'react';
import author from '../me.jpg';

const AboutMe = () => {
	return (
		<div id="about" className="container py-5">
			<div className="row">
				<div className="col-lg-6 col-xm-12">
					<div className="photo-wrap mb-5">
						<img className="profile-img" src={author} alt="author..." />
					</div>
				</div>
				<div className="col-lg-6 col-xm-12">
					<h1 className="about-heading">about us</h1>
					<p>
						Guardian Web Solutions is a freelance product development studio
						expert at design and technology, not shy of exploring exciting
						collaborations. Having experienced multiple industry domains as a
						company, we are seeking to align our skillset with work
						opportunities that are meaningful as they are challenging.
					</p>
				</div>
			</div>
		</div>
	);
};

export default AboutMe;
