import React from "react";
import { LinkedinShareButton, LinkedinIcon } from "react-share";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="d-flex">
              <p>California, USA</p>
            </div>
            <div className="d-flex">
              <a href="tel:555-555-555">+1 (408) 913-5462</a>
            </div>
            <div className="d-flex">
              <p>jason@guardianwebsolutions.com</p>
            </div>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-6 align-items-center">
            <div className="d-flex justify-content-center">
              <LinkedinShareButton
                url={"www.linkedin.com/in/jason-pugatch-3b50b447"}
                quote={"FullStack Developer"}
                hashtag="#javascript"
              >
                <LinkedinIcon className="mx-3" size={36} />
              </LinkedinShareButton>
            </div>
            <p className="pt-3 text-center">
              Copyright&copy;
              {new Date().getFullYear()}&nbsp;Guardian Web Solutions | All
              Rights Reserved
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
